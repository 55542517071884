import spinner from '../../components/spinner/spinner';

var $panel;
var archiveUrl = `${window.location.origin}${window.location.pathname}`;

function init() {

	$panel = $('.offcanvas-panel');

	$(document).on('click', '.panel-link', function(e) {
		e.preventDefault();
		show($(this).attr('href'));
		window.history.replaceState({}, document.title, $(this).attr('href'));
	});

	$(document).on('showProjectDetails', function(e, link) {
		show();
	});

	$(document).on('click', '.offcanvas-panel__close:not([href]), .offcanvas-panel__backdrop', function(e) {
		e.preventDefault();
		hide();
		window.history.replaceState({}, document.title, archiveUrl);
	});

	$(document).on('pageChangeBefore', hide);

	$panel[0].addEventListener('transitionend', fixSafariScrolling);

}

function fixSafariScrolling(e) {
	if (e.eventPhase === 2 && $panel.is('.is-visible') && e.propertyName === 'transform') {
		// Trigger repaint because Safari can't scroll with mouse wheel otherwise :(
		$panel.find('.offcanvas-panel__content').css('overflow', 'hidden');
		setTimeout(function() {
			$panel.find('.offcanvas-panel__content').css('overflow', '');
		}, 1);
	}
}

function show(contentUrl) {

	spinner.init();

	$('.offcanvas-panel__backdrop').addClass('is-visible');
	$('.side-pagination').remove();

	getContent(contentUrl)
		.then(function($content) {

			var $pagination = $('.side-pagination').addClass('available');

			// $panel.attr('aria-hidden', false);
			if ($content) {
				$panel.find('.offcanvas-panel__content').empty().append($content);
			}

			$panel.before($pagination);

			$panel.addClass('is-visible');
			$(document.body).addClass('overflowing');
			$(document.body).addClass('offcanvas-panel--visible');

			window.scrollTo(0, 0);
			// window.focus();

			spinner.quit();

		});

}

function hide() {

	$panel.removeClass('is-visible');

	$('.offcanvas-panel__backdrop').removeClass('is-visible');
	$('.side-pagination').remove();
	$(document.body).removeClass('overflowing');
	$(document.body).removeClass('offcanvas-panel--visible');

}

function getContent(contentUrl) {

	if (!contentUrl) {
		return Promise.resolve();
	}

	return fetch(contentUrl)
		.then(function(response) {
			return response.text();
		})
		.then(function(responseText) {
			return $(responseText).find('#ajaxed-panel');
		});

}


export default {
	init: init
};
