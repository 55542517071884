/**
 * Animate element height when `aria-hidden` attribute is changed.
 * @link https://css-tricks.com/using-css-transitions-auto-dimensions/
 */

class AutoHeight {
  constructor(el) {
    this.el = el;
    this.type = el.getAttribute('data-auto-height-type') || 'accordion';
    this.registerEvents();
  }

  static get isReducedMotion() {
    return window.matchMedia('screen and (prefers-reduced-motion: reduce)').matches;
  }

  registerEvents() {
    /*
     * Listen for `aria-hidden` attribute change.
     */

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutationRecord) => {
        const hasChanged = mutationRecord.oldValue
                            !== mutationRecord.target.getAttribute(mutationRecord.attributeName);

        if (hasChanged && !AutoHeight.isReducedMotion) {
          this.handleAnimation();
        }
      });
    });

    observer.observe(this.el, {
      attributes: true,
      attributeFilter: ['aria-hidden'],
      attributeOldValue: true,
    });

    // Clean style-attribute after animation.
    this.el.addEventListener('transitionend', (event) => {
      if (event.eventPhase === Event.AT_TARGET && event.propertyName === 'height') {
        this.handleAnimationEnd();
      }
    });
  }

  handleAnimation() {
    const isToBeHidden = this.el.getAttribute('aria-hidden') === 'true';
    const isAnimating = this.el.style.height !== '';

    let currentHeight;
    let finalHeight;

    // Target element is a tab to be hidden. No need to animate height.
    // Save current height, because the new tab doesn't know it.
    if (this.type === 'tabs' && isToBeHidden) {
      this.el.parentElement.dataset.autoHeightOldValue = this.el.firstElementChild.scrollHeight;
      return;
    }

    if (!isAnimating) {
      // Force visibility.
      this.el.style.display = 'block';
      this.el.style.visibility = 'visible';
      // Temporarily disable all CSS transitions.
      this.el.style.transition = 'none';
    }

    requestAnimationFrame(() => {
      // Get heights
      if (this.type === 'tabs') {
        currentHeight = parseInt(this.el.parentElement.dataset.autoHeightOldValue, 10)
                        || this.el.firstElementChild.scrollHeight;
        finalHeight = this.el.firstElementChild.scrollHeight;
      } else {
        currentHeight = isToBeHidden ? this.el.firstElementChild.scrollHeight : 0;
        finalHeight = isToBeHidden ? 0 : this.el.firstElementChild.scrollHeight;
      }

      // Set starting height
      this.el.style.height = `${currentHeight}px`;

      requestAnimationFrame(() => {
        // Restore CSS transitions
        this.el.style.transition = null;

        // Animate to final height
        this.el.style.height = `${finalHeight}px`;

        // Trigger cleanup in case heights are same and `transitionend` will not fire.
        if (currentHeight === finalHeight) {
          this.handleAnimationEnd();
        }
      });
    });
  }

  handleAnimationEnd() {
    requestAnimationFrame(() => {
      this.el.style.height = null;
      this.el.style.display = null;
      this.el.style.visibility = null;
      delete this.el.parentElement.dataset.autoHeightOldValue;
    });
  }
}

function init() {
  const autoHeightEls = document.querySelectorAll('[data-auto-height="true"]');

  if (!autoHeightEls.length) return;

  [...autoHeightEls].forEach((el) => {
    new AutoHeight(el);
  });
}


export default {
  init,
};
