class Tablist {
  constructor(tabListEl) {
    this.tabControlEls = tabListEl.querySelectorAll(':scope .accordion__control');
    this.panelElIds = [...this.tabControlEls].map(tabControlEl => `#${tabControlEl.getAttribute('aria-controls')}`);
    this.panelEls = this.panelElIds.length ? document.querySelectorAll(this.panelElIds.join(', ')) : [];

    [...this.tabControlEls].forEach((tabControlEl) => {
      tabControlEl.addEventListener('click', (event) => {
        event.preventDefault();
        toggleTab(tabControlEl);
      });
    });
  }
}

function closeTab(selectedTabEl) {
  const selectedPanelEl = document.querySelector(`#${selectedTabEl.getAttribute('aria-controls')}`);

  // [...this.tabControlEls].forEach((tabControlEl) => {
  //   const isSelected = tabControlEl === selectedTabEl;
  //   isSelected && tabControlEl.setAttribute('aria-expanded', false);
  // });

  // [...this.panelEls].forEach((panelEl) => {
  //   const isSelected = panelEl === selectedPanelEl;
  //   isSelected && panelEl.setAttribute('aria-hidden', true);
  // });

  selectedTabEl.setAttribute('aria-expanded', false);
  selectedPanelEl.setAttribute('aria-hidden', true);
}

function showTab(selectedTabEl) {
  const selectedPanelEl = document.querySelector(`#${selectedTabEl.getAttribute('aria-controls')}`);

  // [...this.tabControlEls].forEach((tabControlEl) => {
  //   const isSelected = tabControlEl === selectedTabEl;
  //   isSelected && tabControlEl.setAttribute('aria-expanded', true);
  // });

  // [...this.panelEls].forEach((panelEl) => {
  //   const isSelected = panelEl === selectedPanelEl;
  //   isSelected && panelEl.setAttribute('aria-hidden', false);
  // });

  selectedTabEl.setAttribute('aria-expanded', true);
  selectedPanelEl.setAttribute('aria-hidden', false);
}

function toggleTab(tabControlEl) {
  if (tabControlEl.getAttribute('aria-expanded') === 'true') {
    closeTab(tabControlEl);
  } else {
    showTab(tabControlEl);
  }
}

function init() {
  const tablistEls = document.querySelectorAll('.accordion-list');

  if (!tablistEls.length) return;

  [...tablistEls].forEach((tablistEl) => {
    new Tablist(tablistEl);
  });
}


export default {
  init,
};
