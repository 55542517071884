/* global $ */

import Isotope from 'isotope-layout';

let gridAPI;
let initialCategory = '';

function createGrid() {
  const $grid = $('.image-listing');
  const postType = $grid.data('type');

  if (!$grid.length) {
    return;
  }

  const searchParams = new URLSearchParams(document.location.search);
  initialCategory = searchParams.get(postType === 'person' ? 'place' : 'category');

  gridAPI = new Isotope($grid[0], {
    itemSelector: '.item',
    percentPosition: true,
  });

  $('.image-listing-filters__trigger').on('click', () => {
    $('.image-listing-filters').toggleClass('is-open');
  });

  $('.image-listing-filters__list a, .top-bar__nav-secondary a').on('click', function filterItems(event) {
    event.preventDefault();
    const $button = $(this);
    let $listItem = $button.closest('li');
    let category = $button.data('category');

    if (initialCategory) {
      category = initialCategory;
      $listItem = $(`.image-listing-filters__list a[data-category="${category}"], .top-bar__nav-secondary a[data-category="${category}"]`).closest('li');
      initialCategory = '';
      if (!$listItem.length) {
        return;
      }
    }

    $('.image-listing-filters__trigger').find('span').text($listItem.text());

    if ($listItem.hasClass('is-active') || !category.length) {
      $listItem.add($listItem.siblings())
        .removeClass('is-active')
        .filter(':first-child').addClass('is-active');

      gridAPI.arrange({
        filter: '*',
      });
    } else {
      $listItem.siblings().removeClass('is-active');
      $listItem.addClass('is-active');

      gridAPI.arrange({
        filter: function gridFilterCallback() {
          return $(this).data('category').indexOf(category) > -1;
        },
      });
    }
  });

  if (initialCategory) {
    $('.image-listing-filters__list a').first().trigger('click');
  } else {
    gridAPI.layout();
  }
}

function destroyGrid() {
  if (gridAPI) {
    $('.image-listing-filters__trigger, .image-listing-filters__list a').off('click');
    gridAPI.destroy();
    gridAPI = null;
  }
}

function init() {
  createGrid();
}

export default {
  init,
};
