// Modern Javascript file for new browsers.
// Build will include less polyfills etc than "legacy" version.

import 'intersection-observer';
import 'focus-visible';
import 'element-qsa-scope';

import focusWithinPolyfill from 'focus-within';
import objectFitImages from 'object-fit-images';
import smoothScrollPolyfill from 'smoothscroll-polyfill';

import autoHeight from '../../components/auto-height/auto-height';
import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import langSwitcher from '../../components/lang-switcher/lang-switcher';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import tablist from '../../components/tablist/tablist';
// import gravityForms from '../../components/gravity-forms/gravity-forms';
// import scrollAnimations from '../../components/scroll-animations/scroll-animations';
// import postListFilters from '../../blocks/post-list-filters/post-list-filters';
import carousels from '../../blocks/carousel/carousel';
// import { debounce } from 'lodash-es';
import imageListing from '../../components/image-listing/image-listing';
import offcanvasPanel from '../../components/offcanvas-panel/offcanvas-panel';
import industryFieldsGraphic from '../../blocks/industry-fields-graphic/industry-fields-graphic';
import unityWebglViewer from '../../blocks/unity-webgl-viewer/unity-webgl-viewer';

const appStart = () => {
  autoHeight.init();
  navPanel.init();
  navMobile.init();
  langSwitcher.init();
  topBar.init();
  lazyLoad.init();
  tablist.init();
  // gravityForms.init();
  // scrollAnimations.init();
  // postListFilters.init();
  carousels.init();
  imageListing.init();
  offcanvasPanel.init();
  industryFieldsGraphic.init();
  lightboxInit();
  unityWebglViewer.init();

  focusWithinPolyfill(document);
  objectFitImages();
  smoothScrollPolyfill.polyfill();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}


/**
 * @link https://stackoverflow.com/questions/47091822/flexbox-alternate-row-and-row-reverse-according-to-rows-number
 */

/*
(function ($) {
  //  preload object array to gain performance
  var $items = $('.b-history-nav li');

  //  run at resize
  $( window ).resize(function() {
    $.fn.setOrder(false,0);
  });

  $.fn.setOrder = function(reverse,idx) {

    var $order = [];

    $items.each(function(i, obj) {

      //  did top value changed
      if (i != 0 && $items.eq(i - 1).offset().top != $(obj).offset().top) {
        reverse = !reverse;
        //  insert index when reverse
        idx = i;
      }
      if (reverse) {
        $order.splice(idx, 0, i);
      } else {
        $order.push(i);
      }
    });

    //  set item's order
    $items.css('order', function(i, val) {
        return $order[i];
    });
  }

  //  run at load
  $.fn.setOrder(false,0);

}(jQuery));
*/


function lightboxInit() {
  $('.lightbox a, a.lightbox').magnificPopup({
    type: 'image',
  });

  // $(document).magnificPopup({
  //   delegate: '[href*=".jpg"], [href*=".png"], [href*=".jpeg"], [href*=".gif"]',
  //   type: 'image',
  // });

  $(document).magnificPopup({
    delegate: '[href*="youtube.com/watch"]',
    type: 'iframe',
  });
}
