// import { rIC } from 'idlize/idle-callback-polyfills';
import Headroom from 'headroom.js';
import { debounce } from 'lodash-es';

function updateSubNavOffset() {
  const topBarEl = document.querySelector('.top-bar');
  const subNavEl = document.querySelector('.top-bar__nav-secondary');
  if (!topBarEl || !subNavEl) {
    return;
  }
  const subNavElOfsset = `${subNavEl.offsetHeight * -1}px`;
  topBarEl.setAttribute('style', `--subnav-offset: ${subNavElOfsset}`);
}

class TopBar {
  constructor({
    // Default options.
    target = '.top-bar',
  } = {}) {
    this.target = document.querySelector(target);
    this.headroomInstance = null;
  }

  // TODO: Use this when rIC works.

  // mountOptimized() {
  //   rIC(() => {
  //     if (this.topBar) {
  //       this.headroomInstance = new Headroom(this.target, {
  //         offset: 300,
  //         tolerance: 5,
  //       });

  //       this.headroomInstance.init();
  //     }
  //   });
  // }

  mount() {
    if (this.target) {
      this.headroomInstance = new Headroom(this.target, {
        offset: 300,
        tolerance: 5,
      });

      updateSubNavOffset();
      window.addEventListener('resize', debounce(updateSubNavOffset));

      this.headroomInstance.init();
    }
  }
}

function init() {
  new TopBar().mount();
}

export default {
  init,
};
