function init() {
  const unityContainer = document.querySelector('#unityContainer');
  if (unityContainer) {
    const { jsonPath } = unityContainer.dataset;
    window.unityInstance = window.UnityLoader.instantiate('unityContainer', `/wp-content/uploads/unity-webgl/${jsonPath}`, {onProgress: window.UnityProgress});
  }
}

export default {
  init,
}
