import {
  Swiper,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
} from 'swiper/js/swiper.esm';

// const btnPrev = document.querySelector('[data-action="swiper-previous"]');
// const btnNext = document.querySelector('[data-action="swiper-next"]');
// const btnScrollToNextBlock = document.querySelectorAll('[data-action="scroll-to-next"]');

// function registerButtonEvents() {
//   [...btnScrollToNextBlock].forEach((btn) => {
//     btn.addEventListener('click', () => {
//       const closest = btn.closest('.b-carousel');
//       let nextSegment = false;

//       if (closest) { nextSegment = closest.nextElementSibling; }
//       if (nextSegment) { nextSegment.scrollIntoView({ behavior: 'smooth' }); }
//     });
//   });
// }

// function toggleVideos(obj) {
//   const { slides, videos } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const video = slides[currentIndex].querySelector('video');

//   [...videos].forEach((v) => { v.pause(); });
//   if (video) { video.play(); }
// }

// function updateTextColor(obj) {
//   const { slides } = obj;
//   const currentIndex = obj.swiper.activeIndex;
//   const currentSlideEl = slides[currentIndex];
//   const currentTextColor = currentSlideEl.querySelector('.b-carousel-item').dataset.textColor;
//   slides[currentIndex].closest('.b-carousel').setAttribute('data-current-text-color', currentTextColor);
// }

function updateFooterFigcaption(obj) {
  const { slides } = obj;
  const currentIndex = obj.swiper.activeIndex;
  const currentSlideEl = slides[currentIndex];
  const currentSlideImageEl = currentSlideEl.querySelector('.b-carousel-item__image');
  const currentFigcaptionEl = currentSlideImageEl
    ? currentSlideImageEl.querySelector('figcaption')
    : null;
  const figcaptionText = currentFigcaptionEl ? currentFigcaptionEl.innerHTML : '&nbsp;';
  const footerFigcaptionEl = document.querySelector(`.${obj.swiper.blockId} .b-carousel__footer-figcaption`);
  footerFigcaptionEl.innerHTML = figcaptionText;
}


function mountCarousels(carouselEls) {
  const sliders = [];

  Swiper.use([Navigation, Pagination, A11y, Autoplay]);

  [...carouselEls].forEach((carouselEl, idx) => {
    const slides = carouselEl.querySelectorAll('.swiper-slide');
    const videos = carouselEl.querySelectorAll('.swiper-slide video');
    const swiperContainerEl = carouselEl.querySelector('.swiper-container');
    // const btnPlayPause = carouselEl.querySelector('.swiper-autoplay-toggle');

    const swiper = new Swiper(swiperContainerEl, {
      // simulateTouch: false,
      speed: 1000,
      autoplay: {
        delay: 10000,
      },
      pagination: {
        el: document.querySelectorAll(`.${swiperContainerEl.dataset.id} .swiper-pagination`),
        type: 'bullets',
        clickable: true,
        bulletElement: 'button',
      },
      navigation: {
        nextEl: document.querySelectorAll(`.${swiperContainerEl.dataset.id} .swiper-button-next`),
        prevEl: document.querySelectorAll(`.${swiperContainerEl.dataset.id} .swiper-button-prev`),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      // autoHeight: true,
      init: false,
    });

    swiper.blockId = swiperContainerEl.dataset.id;

    swiper.on('slideChange', () => {
      // updateShortcuts();
      // toggleVideos(sliders[idx]);
      // updateTextColor(sliders[idx]);
      updateFooterFigcaption(sliders[idx]);
    });

    swiper.on('init', () => {
      updateFooterFigcaption(sliders[idx]);
    });

    // btnPrev.addEventListener('click', () => swiper.slidePrev());
    // btnNext.addEventListener('click', () => swiper.slideNext());

    sliders[idx] = {
      swiper,
      slides,
      videos,
    };

    swiper.init();
  });
}

function init() {
  const carouselEls = document.querySelectorAll('.b-carousel');
  if (carouselEls.length) {
    mountCarousels(carouselEls);
    // if (btnScrollToNextBlock) {
    //   registerButtonEvents();
    // }
  }
}

export default {
  init,
};
